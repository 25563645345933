<template>
  <modal :show-modal="showModal" @close-modal="$emit('close-modal')">
    <template v-slot:header>
      <h4>New Scenario Submitted</h4>
    </template>

    <template v-slot:main-content>
      <p>Scenario submitted successfully!</p>
    </template>

    <template v-slot:footer>
      <button @click="$emit('close-modal')">Close</button>
    </template>
  </modal>
</template>

<script>
import { Modal } from 'rwdi-widgets';

export default {
  name: 'CreateScenarioSuccessModal',
  components: {
    Modal,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style>
</style>