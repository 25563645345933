<template>
  <div class="no-top-margin-all-descendants" id="surrounds-template">
    <div @update:center="centerUpdate" @update:zoom="zoomUpdate" style="height: inherit;" v-if="!showLoadingSpinner">
      <l-map 
        style="height: inherit; width: inherit; position: relative;"
        @update:zoom="zoomUpdate" @update:center="centerUpdate" @update:bounds="boundsUpdated" 
        ref="mapSurrounds"
        :zoom="get_map_zoom"
        :center="get_map_center"
        :bounds="get_map_bounds"
        :options="mapOptions"        
        >
        <l-tile-layer
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
        />
        <l-control position="topleft">
          <div id="map-search-option">
            <b-form class="dropdown-item" id="search-box-container" @submit.prevent>
                <FormulateInput 
                  type='text'
                  id='address-search-input'
                  name='project name'
                  placeholder='Enter Address'
                  v-model="searchField"
                  @keyup="loading=true"
                  @keyup.enter="addressSearch(0)"
                  @keydown.native="addressSearch(delayOSMQuery)"
                />
                <b-button id="reset-search" @click="resetSearch">  
                  <img src="~@/assets/svg/x.svg" alt="Clear Button">
                </b-button>
            </b-form>
            <b-list-group id="map-list-group">
                <hr v-if="showHorizontalLine" class="my-1">
                <div class="d-flex justify-content-center mb-1" v-if="isLoading">
                  <div class="spinner-border text-primary" role="status"></div>
                </div>
                <div v-if="!isLoading">
                 <b-list-group-item v-for="(item, index) in possibleAddresses" 
                    :key="index" 
                    :title="item.label"
                    @click.stop="selectAddress(index)"
                  >
                    {{ item.label }}
                  </b-list-group-item>
                </div>
            </b-list-group>
          </div>
        </l-control>
        <l-marker
           v-if="marker.lat && marker.lng"
           :lat-lng="marker" :icon="this.icon"> 
        </l-marker>
      </l-map>
    </div>
    <div v-if="showLoadingSpinner" class="h-100 d-flex justify-content-center align-items-center">
      <loading-state/>
    </div>
  </div>
</template>
<script>

import { LMarker, LMap, LTileLayer, LControl } from 'vue2-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { icon, latLng, latLngBounds } from 'leaflet';
import LoadingState from '@/components/views/LoadingState';
import axios  from 'axios';

export default {
  name: 'SurroundsGeolocator',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LoadingState
  },
  props: {
    addressQuery: {
      type: String,
      required: false
    },
    tabNum: {
      type: Number,
      required: true
    },
    typedPositionLat: {
      required: false
    },   
    typedPositionLng: {
      required: false
    }
  },
  data() {
    return {
      marker: [], 
      showLoadingSpinner: false,
      isLatValid: false,
      isLngValid: false,
      center: latLng(0, 0),
      typingTimer: null,
      mapInitialized: false,
      possibleAddresses: [],
      delayOSMQuery: 1000,
      bounds: null,
      loading: false,
      searchField: '',
      OSM: new OpenStreetMapProvider(),
      userLocation: {},
      icon: icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl: '/src/assets/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
      position: latLng(0, 0),
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      },
      zoom: 18,
      address: '',
      mapOptions: {
        attributionControl: false,
        animateToCenter: false,
        touchZoom: false,
        zoomSnap: 0.75,
        zoomPanOptions: {
          animate: false,
          duration: 0,
          easeLinearity: 1,
          noMoveStart: true,
          noMoveEnd: true,
          worldCopyJump: false,
          animateZoom: false,
          animatePan: false,
        },
      },   
    };
  },
  mounted() {
    this.$refs.mapSurrounds.mapObject.on('click', (event) => {
      //clicked lat/long sent to parent component (CreateProjectModal) which sets lat/long.  the lat/long are passed as props back
      //to this component in typedPositionLat and typedPositionLng.  Watchers on those props validate the input and drop a marker if both are valid
      this.$emit('coordinates', { clickedCoordinates: event.latlng});
    });
  },
  methods: {
    dropMarker(latLng) {
      this.marker = latLng;  //set the marker on the map
      this.$emit('coordinates', { marker: this.marker});  //let the CreateProjectModal know that there are valid coordinates selected.  this enables the 'next' button
    },
    removeMarker() {
      this.marker = [];  //visually removes the marker
    },
    async addressSearch(delayOSMQuery){
      this.loading = true;
      clearTimeout(this.typingTimer);
      let fetchPossibleAddresses = async() => {
        this.possibleAddresses = await this.OSM.search({ query: this.searchField});
        const zoomLevels = {
          'house': 20,
          'street': 15,
          'district': 12,
          'city': 10,
          'county': 7,
          'state': 6,
          'country': 4,
          'apartments': 15
        };
        this.zoom = zoomLevels[this.possibleAddresses[0]?.raw?.type] || 18;
        this.resizeMapTiles();
        this.loading = false;
      };
      this.typingTimer = setTimeout(fetchPossibleAddresses, delayOSMQuery);
    }, 
    resetSearch(){
      this.possibleAddresses = [];
      this.searchField = '';
      document.querySelector('#address-search-input').value = '';
    },
    selectAddress(index){
      if(this.possibleAddresses.length > 0 ){
        this.center = latLng(this.possibleAddresses[index].y,this.possibleAddresses[index].x);
        this.possibleAddresses = [];
      }
    },
    resizeMapTiles(){
      setTimeout(() => {
        // Invalidated the current size(state) of the map and re-renders it 
        this.$refs.mapSurrounds.mapObject.invalidateSize(); 
      }, 300);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
      if(!this.mapInitialized & this.tabNum == 1){
        document.querySelector('#address-search-input').value = this.address;
        this.mapInitialized = true; 
      }
      this.resizeMapTiles();
    },

    boundsUpdated (bounds) {
      this.bounds = bounds;
    }, 

  },  
  computed: {
    isLoading() {
      return this.loading ? true : false; 
    },
    showHorizontalLine() {
      if(this.possibleAddresses.length > 0 || this.loading ){
        return true;
      }
      return false;
    },
    get_map_center(){
      if(this.center) {
        let center =  latLngBounds([[this.center.lat, this.center.lng]]);
        return center.getCenter();
      } else {      
        return [0, 0];      
      }
    },
    get_map_bounds(){
      let coords =  latLngBounds([[this.position.lat, this.position.lng]]);
      return coords.pad(0.08);
    },
    get_map_zoom(){
      return this.zoom;
    },
  },
  watch: {
    addressQuery: {
      async handler(newValue) {
        this.address = newValue;
        this.mapInitialized = false;
      }
    },
    tabNum: {
      async handler(newValue) {
        // When newValue is equal to 1 Surrounds map is selected therefor map needs to be re-rendered 
        // resizeMapTiles() refreshes the tiles of the map when Project's Location panel is shown
        if(newValue==1 && !this.mapInitialized){
          const endpoint = 'https://nominatim.openstreetmap.org/search';
          const params = {
            q: `${this.address}`,
            format: 'geocodejson',
            addressdetails: 1,
            namedetails: 1,
            extratags: 1,
            limit: 5,
            dedupe: 1,
          };
          axios.create().get(endpoint, { 
            params,
            timeout: 15000,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => {
            const data = response.data;
            let possibleAddresses = data?.features;
            const zoomLevels = {
              'house': 20,
              'street': 15,
              'district': 12,
              'city': 10,
              'county': 7,
              'state': 6,
              'country': 4
            };
            this.zoom = zoomLevels[possibleAddresses[0]?.properties?.geocoding?.type] || 10;
            this.center = latLng(possibleAddresses[0]?.geometry?.coordinates[1], possibleAddresses[0]?.geometry?.coordinates[0]);
            
            if (!this.center) {
              this.center = latLng(0, 0);
              this.zoom = 4;
            }
            this.position = this.center;
            
            document.querySelector('#address-search-input').value = this.address;
          }).catch(error => {
            console.error(error);
            this.center = latLng(0, 0);
            this.zoom = 4;
          });
        }
      }
    },
    typedPositionLat: {
      //first in 2 cases:
      // 1 - the user types in a value to the Latitude field in the CreateProjectModal
      // 2 - the user clicks on the map.  This sends a click event to the CreateProjectModal, which sets the TypedPositionLat field, which triggers this event
      // In either case, if the new value is valid then the position of the map is updated.  If we have a valid lat and lng, then we drop a marker which displays 
      // on the map and sends an event back to the CreateProjectModal to update it's this.latitude and this.longitude properties and enable the next button
      handler(newValue) {
        newValue=parseFloat(newValue);
        if(!isNaN(newValue) && newValue >= -90 && newValue <= 90){
          this.position = latLng(newValue, this.position?.lng);
          this.isLatValid = true;
          if (this.isLatValid && this.isLngValid) {
            this.dropMarker(this.position);
          }
        } else {
          this.isLatValid = false;
        } 
      }
    },
    typedPositionLng: {
      //first in 2 cases:
      // 1 - the user types in a value to the Longitude field in the CreateProjectModal
      // 2 - the user clicks on the map.  This sends a click event to the CreateProjectModal, which sets the TypedPositionLng field, which triggers this event
      // In either case, if the new value is valid then the position of the map is updated.  If we have a valid lat and lng, then we drop a marker which displays 
      // on the map and sends an event back to the CreateProjectModal to update it's this.latitude and this.longitude properties and enable the next button
      handler(newValue) {
        newValue=parseFloat(newValue);
        if(!isNaN(newValue) && newValue >= -180 && newValue <= 180){
          this.position = latLng(this.position?.lat, newValue);
          this.isLngValid = true;
          if (this.isLatValid && this.isLngValid) {
            this.dropMarker(this.position);
          }
        } else { 
          this.isLngValid = false;
        }
      }
    },
    isLatValid(newValue) {
      this.$emit('latStatus', { status: newValue });
      
      if (!newValue) {
        this.removeMarker();
      }
    },
    isLngValid(newValue) {
      this.$emit('lngStatus', { status: newValue });

      if (!newValue) {
        this.removeMarker();
      }
    }
  },
};
</script>


<style>

#reset-search{
  padding: 0;
  border: none;
  background-color: transparent;
  margin-right: 0.75rem;
}

#reset-search img{
  height: 1rem;
}

#search-box-container{
 display: flex;
 justify-content: space-between;
 background-color: #fff;
 padding: 0.25rem 0;
 align-items: center;
}

#search-box-container .formulate-input{
  margin: 0;
  width: 21.25rem;
}

#address-search-input{
  padding: 0.313rem 0 0.313rem 0.625rem;
  font-size: 0.75rem;
  border:none;
}

#map-list-group .list-group-item:hover{
  color: #fff;
  background-color: rgba(12, 31, 41, 0.8);
}

#map-list-group hr{
  background-color: rgba(12, 31, 41, 0.8);
}

#map-list-group .list-group-item{
  padding: 0;
  line-height: 1.5rem;
  padding: 0 0.5rem;
  border: 0.063rem solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#map-search-option{
  width: 21.875rem;
  background-color: #fff;
  margin-left: 19%;
}

@media screen and (min-width: 48rem) {
  #map-search-option{
    margin-left: 32%;
  }
}

#map-search-option .form-group.dropdown-item:hover{
  background-color: #fff;
}

#map-search-option .form-group.dropdown-item{
  margin-bottom: 0;
  padding: 0;
}

#surrounds-template{
  height: 23.438rem;
 }

.leaflet-control-container .leaflet-top.leaflet-left{
  display: flex;
  width: 100%;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control{
  height: fit-content;
}

</style>
