<template>
  <div class="project-list-container">
    <div v-if="!this.isLoading && !eulaModalIsVisible" id="user-bar">
      <marketing-bar />
    </div>

    <div class="header-container" v-if="!this.isLoading && !eulaModalIsVisible">
      <custom-button class='mr-2' v-if="this.canAddProject" type="button" theme='ghost-button' @click="openCreateProjectModal()">+ Add Project</custom-button>
    </div>
    <div class="header-container" v-if="!this.isLoading && !eulaModalIsVisible">
      <b-badge class="p-2 search-badge" v-if="this.searchingProjects">
        <b-icon class="float-right search-badge-x-icon" icon="x-circle-fill" @click="cancelSearch()"></b-icon>
        <span class="mr-2">{{this.searchProjectsQuery}}</span>
      </b-badge>
      <FormulateInput
        type='search'
        class='search-input'
        name='project name'
        placeholder='search projects'
        v-model='searchProjectsQueryInput'
        v-on:keyup.enter='searchProjects' />
    </div>
    <div class="list-container" v-if="!eulaModalIsVisible">
      <loading-state v-if="this.isLoading" />
      <div v-else-if="this.errorMsg" class="notification-banner-container">
        <notification-banner
        theme='error'
        title='Content Unavailable'
        :alternate="true"
        :message="this.errorMsg"
        :show-close-button="false" />
      </div>
      <empty-state v-else-if="this.projects && !this.projects.length && !this.searchingProjects"  :image="emptyStateImageAsset" image-alt-text='Get Started'>
        <template v-slot:title><h2>Get Started</h2></template>
        <template v-slot:message><p>Rapid insights to guide your design decisions now, saving you from costly mistakes later.</p></template>
        <template v-slot:call-to-action><div class="button-row"><a class="button" href="https://rwdi.com/contact">Contact your RWDI project manager</a></div></template>
      </empty-state>
      <empty-state v-else-if="this.projects && !this.projects.length && this.searchProjects">
        <template v-slot:message><p>No search results match your query</p></template>
      </empty-state>
      <project-card v-else v-for="project in this.projects" :key="project.id" :project="project" @add-scenario="openCreateScenarioModal(project.id)" />
      <loading-state v-if="this.loadingNextPage && !this.isLoading" />
      <create-project-modal :show-modal="createProjectModalIsVisible" @close-modal="createProjectModalIsVisible = false"/>
      <create-scenario-modal :show-modal="createScenarioModalIsVisible" :project-id="scenarioProjectId" @close-modal="closeCreateScenarioModal" />
      <create-scenario-success-modal :show-modal="createScenarioSuccessModalIsVisible" @close-modal="closeCreateScenarioSuccessModal" />
    </div>
    <eula-modal :show-modal="eulaModalIsVisible" :eula="eula" :submitAcceptedEula="submitAcceptedEula" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectCard from './ProjectCard';
import EmptyState from '@/components/views/EmptyState';
import LoadingState from '@/components/views/LoadingState';
import CreateProjectModal from './CreateProjectModal';
import CreateScenarioModal from './CreateScenarioModal';
import CreateScenarioSuccessModal from './CreateScenarioSuccessModal';
import EulaModal from '@/components/permissions/EulaModal';
import MarketingBar from './MarketingBar';

import {
  NotificationBanner,
  CustomButton,
} from 'rwdi-widgets';

import {
  ADD_PROJECT,
} from '@/constants/permissions';

import {
  GET_EULA,
  SUBMIT_ACCEPTED_EULA
} from '@/store/actions/permissions';

import { EventBus } from '@/network/eventbus';

// using require instead of import as a workaround to this bug: https://stackoverflow.com/questions/49263429/jest-gives-an-error-syntaxerror-unexpected-token-export
let productFruitsInit = null;
if(process.env.NODE_ENV !== 'test') {
  productFruitsInit = require('product-fruits'); 
}


export default {
  name: 'ProjectList',
  components: {
    ProjectCard,
    EmptyState,
    LoadingState,
    NotificationBanner,
    CustomButton,
    CreateProjectModal,
    CreateScenarioModal,
    CreateScenarioSuccessModal,
    EulaModal,
    MarketingBar
  },
  pageTitle: 'Projects',
  computed: {
    eulaIsAccepted() {
      return this.$store.getters['eulaIsAccepted'];
    },
    errorMsg() {
      return this.$store.getters['project/error'] ? 'Please contact support if you continue to see this error.' : null;
    },
    isLoading() {
      return this.$store.getters['project/loading']; 
    },
    projects() {
      return this.$store.getters['project/allProjects']?.slice();
    },
    emptyStateImageAsset() {
      return require('@/assets/svg/empty-state.svg');
    },
    canAddProject() {
      return (this.$store.getters['allowedPermissions'] || {})[ADD_PROJECT];
    },
    createdScenarioInformation() {
      return this.$store.getters['project/createdScenarioInformation'];
    },
    createScenarioSuccessModalIsVisible() {
      return this.$store.getters['project/createScenarioSuccessModalVisible'];
    },
    ...mapGetters(['eula','loggedInUser']),
    ...mapGetters('project', ['productFruits'])
  },
  async created() {
    let searchQueryFromRoute = this.$route.query?.filter;
    if (searchQueryFromRoute) {
      this.allPagesLoaded = false;
      this.$store.dispatch('project/reset');
      this.executeSearch(searchQueryFromRoute);
    } else {
      if(!this.loggedInUser?.is_otp_user) this.startPagination();
    }
  },
  data() {
    return {
      projectCode: 'r8THtdxQNvKIzYmL', // referred to as a workspace code oin product-fruits 
      createProjectModalIsVisible: false,
      createScenarioModalIsVisible: false,
      eulaModalIsVisible: false,
      scenarioProjectId: null,
      loadingNextPage: false,
      allPagesLoaded: false,
      searchingProjects: false,
      searchProjectsQueryInput: '',
      searchProjectsQuery: '',
      productFruitsInit: productFruitsInit
    };
  },
  async mounted() {
    let t1 = this.$store.dispatch(GET_EULA);
    let t2 = this.$store.dispatch('project/getJobTypes'); // also called in AppContainer.vue but currently needs to be called here as well to ensure it loads when navigating back via top left icon button in viewer
    let t3 = this.$store.dispatch('project/getCriteria'); //also called in AppContainer
    window.addEventListener('scroll', this.getNextPageOnScrollToBottom);
    EventBus.$on('SUBMITML_COMPLETE', this.onSubmitComplete);
    await Promise.all([t1, t2, t3]);
  },
  beforeDestroy() {
    EventBus.$off('SUBMITML_COMPLETE', this.onSubmitComplete);
    window.removeEventListener('scroll', this.getNextPageOnScrollToBottom);
  },
  methods: {
    closeDropdownMenu(){
      EventBus.$emit('CLOSE_USER_DROPDOWN');
    },
    initializeProductFruits() {
      if (this.loggedInUser) {
        if(this.productFruitsInit && !this.productFruits){
          const user = this.loggedInUser ;
          let showDashTour = 2;
          if(user.role.includes('role: org admin'))
            showDashTour = 3;
          if(user.role.includes('role: viewer'))
            showDashTour = 1;
          const showTour = user.show_welcome_tour ? 1 : 0;
          const userInfoWithHMAC = {
            username: user.pf_user_id,
            props: { 
              showTour: showTour,
              showDashTour: showDashTour,
            },
            hmac: { 
              hash: user.usernameHMAC,
              expiration: null 
            },
          };
          let projectCode = this.projectCode;
          // Removing the VUE_APP_PRODUCT_FRUITS_TEST_PROJECT_CODE may resolve issues/errors related whitelisting the dashboard tour (403/401 errors)  
          // Please note that the  VUE_APP_PRODUCT_FRUITS_TEST_PROJECT_CODE env should only be present when a tour(dashboard/project) is in development 
          if(process.env.VUE_APP_PRODUCT_FRUITS_PROJECT_CODE && String(process.env.VUE_APP_PRODUCT_FRUITS_PROJECT_CODE).length > 1)
            projectCode = String(process.env.VUE_APP_PRODUCT_FRUITS_PROJECT_CODE);
          this.closeDropdownMenu();
          productFruitsInit.productFruits.init(projectCode, 'en', userInfoWithHMAC);
          this.$store.dispatch('project/setProductFruits', true);
        }
      }
    },
    submitAcceptedEula() {
      this.$store.dispatch(SUBMIT_ACCEPTED_EULA, { eula_id: this.eula.id });
    },
    openCreateProjectModal() {
      this.createProjectModalIsVisible = true;
    },
    sortProjectByNameOrAddress(firstProject, secondProject) {
      const firstProjectName = firstProject.name || firstProject.address_1;
      const secondProjectName = secondProject.name || secondProject.address_1;
      return firstProjectName.localeCompare(secondProjectName);
    },
    openCreateScenarioModal(projectId) {
      this.createScenarioModalIsVisible = true;
      this.scenarioProjectId = projectId;
    },
    closeCreateScenarioModal(scenarioInformation) {
      this.createScenarioModalIsVisible = false;
      this.scenarioProjectId = null;
      if (scenarioInformation != null) {
        this.showViewer();
      }
    },
    showViewer() {
      this.$router.push({
        name: 'ViewerContainer',
        params: {
          id: this.createdScenarioInformation.projectId,
          study: this.createdScenarioInformation.studyId,
          configuration: this.createdScenarioInformation.configurationId,
          tabName: 'Viewer'
        }
      });
    },
    closeCreateScenarioSuccessModal() {
      this.$store.dispatch('project/setCreateScenarioSuccessModalVisible', false);
    },
    async getNextPageOnScrollToBottom() {
      if(!this.loadingNextPage) {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= 0.4 * document.documentElement.offsetHeight;
        if (bottomOfWindow && !this.allPagesLoaded) {
          try {
            this.loadingNextPage = true;
            if (this.searchingProjects) {
              await this.$store.dispatch('project/getSearchPage', this.searchProjectsQuery);
            } else {
              await this.$store.dispatch('project/getPage');
            }
            this.loadingNextPage = false;
          } catch(e) {
            this.allPagesLoaded = true;
            this.loadingNextPage = false;
          }
        }
      }
    },
    cancelSearch() {
      this.searchingProjects = false;
      this.searchProjectsQuery = '';
      this.searchProjectsQueryInput = '';
      this.$router.push({ path: this.$route.path });
      this.startPagination();
    },
    async searchProjects() {
      if(this.searchProjectsQueryInput === ''){
        this.cancelSearch();
      } else {
        this.$router.push({ path: this.$route.path, query: { filter: this.searchProjectsQueryInput } });
        //it's no longer necessary to call executeSearch directly as the search filter is passed to the route and a watch on the route will execute the search
        //this.executeSearch(this.searchProjectsQueryInput);
      }
    },
    async executeSearch(searchString) {
      //this can be called from:
      // - the searchProjects() method above (when a user types in the search string)
      // - from the created() hook, if a url that contains the search filter in the query string
      // - from a watch on $route, which triggers if a user clicks 'back' in the browser which re-adds a removed search string

      this.allPagesLoaded = false;
      this.searchingProjects = true;
      this.loadingNextPage = false;
      await this.$store.dispatch('project/getProjectsByName', searchString);
      this.searchProjectsQuery = searchString;
    },
    async startPagination() {
      this.allPagesLoaded = false;
      this.$store.dispatch('project/reset');
      await this.$store.dispatch('project/getJobTypes'); // this currently needs to be called here because the job levels and criteria are lost in the reset call.  Would be better to not reset them...
      await this.$store.dispatch('project/getCriteria'); // this currently needs to be called here because the job levels and criteria are lost in the reset call.  Would be better to not reset them...
      this.loadingNextPage = true;
      await this.$store.dispatch('project/getPage');
      this.loadingNextPage = false;
    },
    onSubmitComplete(args) {
      const projectId = parseInt(args.project_id);
      const configId = parseInt(args.scenario_id);

      const matchingProject = this.projects.find(x => x.id == projectId);
      if (matchingProject) {
        const simulation = matchingProject.studies[0].simulation_labels.find(x => x.configurationId == configId);
        if (simulation) {
          this.setSimulationCategory({ projectId: projectId, simulationId: simulation.id, newCategoryValue: 'RESULT' });
        }
      }
    },
    ...mapActions({
      setSimulationCategory: 'project/setSimulationCategory'
    })
  },
  watch: {
    eulaIsAccepted(newValue){
      if(newValue)
        this.initializeProductFruits();
    },
    async webSocketConnectionInfo(newValue) {
      if (newValue) {
        await this.connectToWebSocket(newValue);
      }
    },
    eula(newValue) {
      this.eulaModalIsVisible = !!newValue;
    },
    projects(newValue) {
      if (newValue) {
        if(newValue.length === 0) this.allPagesLoaded = false;
      }
    },
    $route(to) {
      //watching the route catches users removing a search string, then hitting back, which should re-execute the search
      let searchQueryFromRoute = to.query?.filter;
      if (searchQueryFromRoute) {
        this.executeSearch(searchQueryFromRoute);
      }
    }
  }
};
</script>

<style>
.productfruits--container {
  margin-top: 0;
}
</style>

<style scoped>



.project-list-container {
  display: flex;
  flex-direction: column;
}

.list-container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 75rem;
  width: calc(100% - (1.5em + 1vw));
  padding: 0 0.5em calc(1.5em + 1vw);
}

.header-container {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: calc(100% - (1.5em + 1vw));
  max-width: 75rem;
  padding: 1vw 0.5em;
}

.notification-banner-container {
  margin: 0 auto;
  width: 100%;
  max-width: 75rem;
  padding: calc(1.5em + 1vw) 0.5em;
}

.input {
  margin-right: 2rem;
}

.input-container {
  display: flex;
  flex-direction: row;
}

.input-container > * {
  width: 50%;
  margin-top: 0;
}

.input-container span {
  display: flex;
  align-items: flex-end;
  padding:.6em 1em .5em;
}

.input-container label {
  margin-bottom: 0.5em;
}

.button-container > * {
  margin-top: 0;
}

.loading-spinner {
  height: 1.25em;
  width: 1.25em;
  padding: 0 1.5rem;
}

.search-badge {
  height: 2em;
  line-height: 2em;
  padding-left: 0.938rem !important;
  padding-right: 0.938rem !important;
}

.search-badge-x-icon {
  height: 2em;
}

.search-input {
  margin-top: 0rem;
  margin-left: 1.563rem;
  margin-right: 1.55rem;
}

</style>