<template>
  <modal :show-modal="createProjectModalIsVisible" @close-modal="$emit('close-modal')" :contentClass='"fixed-size-window"'>
    <template v-slot:header>
      <div class="d-flex flex-row justify-content-between no-top-margin-all-descendants">
        <h4>New Project</h4>
        <small>{{stepText}}</small>
      </div>
    </template>
    <template v-slot:main-content>
      <div>
        <FormulateForm @submit="createProject" class="fixed-height-body">
          <b-tabs content-class="mt-3" justified pills>
            <b-tab title="Details" :disabled="tabNum!=0" :active="tabNum==0" title-link-class="p-0 bg-transparent">
              <template #title>
                <div style="position:relative">
                  <hr class="m-0" style="position:absolute; top:1.563rem; z-index: 0;" >
                  <div class="d-flex flex-column align-items-center no-top-margin-all-descendants">
                    <b-icon-journal-text font-scale="2" variant="light" :class="tabIconClass(0)" style="z-index: 1;"></b-icon-journal-text>
                    <small :class="tabTextClass(0)">Details</small>
                  </div>
                </div>
              </template>
              <div class="mt-2 no-top-margin-all-descendants">             
                <FormulateInput
                  label='Project name'
                  placeholder='Name of the project (required)'
                  name="project-name"
                  id="project-name"
                  maxlength="255"
                  validation='required'
                  v-model="projectName" />
                <FormulateInput
                  label='Reference ID'
                  placeholder='Reference ID for the project (optional)'
                  name="client-project-id"
                  id="client-project-id"
                  maxlength="255"
                  v-model="clientId"
                  title='an optional Id provided by the client to help them identify the project'/>
                <FormulateInput
                  label='Address Line 1'
                  placeholder='Address for the project (required)'
                  name="address-1"
                  id="address-1"
                  maxlength="255"
                  validation='required'
                  v-model="address1"/>
                <FormulateInput
                  label='Address Line 2'
                  placeholder='Address Line 2 for the project (optional)'
                  name="address-2"
                  id="address-2"
                  maxlength="255"
                  v-model="address2"/>
                <div v-if="canFetchMetManually">
                  <FormulateInput
                    label='Get weather data'
                    :wrapper-class="['create-project-checkbox-wrapper']"
                    type='checkbox'
                    name='project-met'
                    id='project-met'
                    v-model='fetchMet'                               
                    @input="updateFetchMetBox"/>        
                </div>
                <div>
                <FormulateInput
                    label='Auto generate surrounds/context model for this project'
                    :wrapper-class="['create-project-checkbox-wrapper']"
                    type='checkbox'
                    name='generate-osm-surounds'
                    id='generate-osm-surrounds'
                    v-model='generateOSMSurronds'                               
                    @input="updateGenerateOSMSurroundsBox"
                >
                  <template #label="{ label, id }">
                    <label :for="id">
                      <span id="generate-osm-surrounds-span"> {{ label }} </span>
                      <svg xmlns="http://www.w3.org/2000/svg" 
                        v-tooltip='`A context model will be generated using Open Street Map data, please verify it for accurate building placements and heights. The file will be downloadable from the "Resources" section of the project properties page`'
                        width="16" height="16" fill="#007bff" class="bi bi-question-circle mb-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg>
                    </label>
                  </template>
                </FormulateInput>
                </div>
              </div>            
            </b-tab>
            <b-tab title="Project Location" :disabled="tabNum!=1" :active="tabNum==1" title-link-class="p-0 bg-transparent">
              <template #title>
                <div style="position:relative">
                  <hr class="m-0" style="position:absolute; top:1.563rem; z-index: 0;" >
                  <div class="d-flex flex-column align-items-center no-top-margin-all-descendants">
                    <b-icon-geo-alt font-scale="2" variant="light" :class="tabIconClass(1)" style="z-index: 1;"></b-icon-geo-alt>
                    <small :class="tabTextClass(1)">Project Location</small>
                  </div>
                </div>  
              </template>
              <div style="position: relative;">
                <p class="small">Please click on the map to place a marker on the location of your project. The latitude and longitude will be used for surrounds generation and weather data collection.</p>
              </div>
              <div id="surrounds-map"> 
                  <surrounds-geolocator 
                   @latStatus="setLatStatus"
                   @lngStatus="setLngStatus"
                   @coordinates="setCoordinates" 
                   :addressQuery="address1"
                   :tabNum="tabNum"
                   :typedPositionLat="typedPositionLat"
                   :typedPositionLng="typedPositionLng"
              />
              </div>
              <div class='mx-3 mt-4 d-flex justify-content-around'>                       
                    <div class="mt-0 py-0 pr-0 d-flex align-items-baseline">
                      <FormulateInput
                        type="number"
                        label='Latitude:'
                        label-class="mr-1 mb-0"
                        v-model="typedPositionLat"
                        name="latitude"
                        id="latitude"
                        :validation="[
                          ['required'],
                          ['max', 90],
                          ['min', -90]
                        ]"
                        wrapper-class="d-flex align-items-center"
                      />  
                      <img v-if="latIsValid"  class="lat-is-valid mt-0 h-25 mx-1" src="~@/assets/svg/check-circle-fill.svg" alt="Latitude is Valid">
                </div>
                <div class=" mt-0 py-0 pr-0 d-flex align-items-baseline">
                  <FormulateInput
                    type="number"
                    v-model="typedPositionLng"
                    label='Longitude:'
                    label-class="mr-1 mb-0"
                    name="longitude"
                    id="longitude"
                    :validation="[
                      ['required'],
                      ['max', 180],
                      ['min', -180]
                    ]"
                    wrapper-class="d-flex align-items-center"
                  />
                  <img v-if="lngIsValid" class="lng-is-valid mt-0 h-25 mx-1" src="~@/assets/svg/check-circle-fill.svg" alt="Longitude is Valid">
                </div>   
              </div>
            </b-tab>
            <b-tab v-if="fetchMet" title="Weather" :disabled="tabNum!=2" :active="tabNum==2"  class="no-top-margin-all-descendants"  title-link-class="p-0 bg-transparent">
              <template #title>
                <div style="position:relative">
                  <hr class="m-0" style="position:absolute; top:1.563rem; z-index: 0;" >
                  <div class="d-flex flex-column align-items-center no-top-margin-all-descendants">
                    <b-icon-clouds font-scale="2" variant="light"  :class="tabIconClass(2)" style="z-index: 1;"></b-icon-clouds>
                    <small :class="tabTextClass(2)">Weather</small>
                  </div>
                </div>
              </template>
              <weather-data-form 
                :latitude="latitude"
                :longitude="longitude"
                :displayStationsFlag="displayStationsFlag"
                @setDisabled="setWeatherDataFormDisabled"
                @setCustomMetUploadRadioValue="setCustomMetUploadRadioValue"
                @setSelectedStationData="setSelectedStationData"
                @setCustomWeatherDataFile="setCustomWeatherDataFile"
                @setCustomMetUploadComplete="setCustomMetUploadComplete"
                @setCustomWeatherDataHeight="setCustomWeatherDataHeight"
                @setMetFileFormatRadioValue="setMetFileFormatRadioValue"
                @setTimeframe="setTimeframe"
                @setClimateScenario="setClimateScenario"
                @setCustomWeatherDataLabel="setCustomWeatherDataLabel"/>
            </b-tab> 
            <b-tab title="Review" :disabled="tabNum!=3" :active="tabNum==3" title-link-class="p-0 bg-transparent">
              <template #title>
                <div style="position:relative">
                  <hr class="m-0" style="position:absolute; top:1.563rem; z-index: 0;" >
                  <div class="d-flex flex-column align-items-center no-top-margin-all-descendants">
                    <b-icon-check-circle font-scale="2" variant="light"  :class="tabIconClass(3)" style="z-index: 1;"></b-icon-check-circle>
                    <small :class="tabTextClass(3)">Review</small>
                  </div>
                </div>
              </template>
              <div>
              <h5 class="text-capitalize">Summary:</h5>
              <table class="table table-borderless p-1" style="font-size:small">
                <tbody>
                  <tr>
                    <td class="pl-5">Name:</td>
                    <td v-text="projectName"></td>
                  </tr>
                  <tr>
                    <td class="pl-5">Address:</td>
                    <td v-text="address1"></td>
                  </tr>
                  <tr>
                    <td class="pl-5"></td>
                    <td v-text="address2"></td>
                  </tr>
                  <tr>
                    <td class="pl-5">Latitude:</td>
                    <td v-text="latitude"></td>
                  </tr>
                  <tr>
                    <td class="pl-5">Longitude:</td>
                    <td v-text="longitude"></td>
                  </tr>
                  <tr class="m-0">
                    <td class="pl-5">Weather Source:</td>
                    <td v-text="getWeatherSourceType()"></td>
                  </tr>
                  <tr>
                    <td class="pl-5"></td>
                    <td v-text="getWeatherSelection()"></td>
                  </tr>
                  <tr v-if="!customMetUploadComplete && customMetUploadSelected">
                    <td class="pl-5"></td>
                    <td>
                      <span class="mr-2">uploading MET data...</span>
                      <b-spinner small></b-spinner>
                    </td>
                  </tr>
                  <tr class="m-0">
                    <td class="pl-5"> Surrounds:</td>
                    <td v-if="generateOSMSurronds"> The generated Surrounds file can be downloaded in the project's properties page under the resources tab </td>
                    <td v-else> No Surround file will be generated</td>
                  </tr>
                </tbody>
              </table>
              </div>
            
            </b-tab>         
          </b-tabs>
        </FormulateForm>
      </div>  
    </template>
    <template v-slot:footer>
      <div class="button-container d-flex flex-row justify-content-between" v-if="!createProjectIsLoading">
        <custom-button type="button" theme='ghost-button' @click="$emit('close-modal');resetAll()">Cancel</custom-button>
        <div class="d-flex flex-row" >
          <b-button v-if="tabNum!=0" variant="outline-secondary" class='mr-3 px-4' type="button" @click="onPrevious()">Back</b-button>  
          <b-button v-if="tabNum!=3" variant="primary" class="px-4" type="button" :disabled="nextButtonDisabled()" @click="onNext()">Next</b-button>  
          <b-button v-if="tabNum==3" variant="primary" class="px-4" type="button" :disabled="!canSubmit" @click="createProject()">Create</b-button>
        </div>   
      </div>
      <b-spinner class="float-right" small variant="primary" v-else/>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Modal } from 'rwdi-widgets';
import { FETCH_MET_MANUALLY } from '@/constants/permissions';
import SurroundsGeolocator from './SurroundsGeolocator';
import WeatherDataForm from './WeatherDataForm';

import {
  CustomButton
} from 'rwdi-widgets';

export default {
  name: 'CreateProjectModal',
  components: {
    Modal,
    CustomButton, 
    SurroundsGeolocator,
    WeatherDataForm
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      createProjectModalIsVisible: false,
      createProjectIsLoading: false,
      generateOSMSurronds: false,
      projectName: null,
      address1: null,
      address2: null,
      latitude: null,
      longitude: null,
      isLngStatusValid: false,
      isLatStatusValid: false,
      typedPositionLat: null,
      typedPositionLng: null,
      loading: false,
      clientId: null,
      displayStationsFlag: false,
      customWeatherDataFile: null,
      customMetUploadComplete: false,
      customWeatherDataHeight: 600,
      customWeatherDataLabel: '',
      fetchMet: true,
      generateSurrounds: false,
      tabNum: 0,
      stationData: null,
      stations: [],
      selectedStation: null,
      selectedStationData: {},
      fetchMetStationData: false,
      customMetUploadRadioValue: 0,
      metFileFormatRadioValue: 'MIS',
      center: [0, 0],
      markeritems: [], 
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      weatherDataFormDisabled: true,
      futureMetTimeFrame: '2050',
      futureMetClimateScenario: 'SSP1'

    };
  },
  async created() {
    await this.$store.dispatch('project/getProjectAssetUploadUrl');
    this.airKeys = ['aero', 'drom', 'air', 'field', 'base', 'flug'];  //constant.  created here and not as part of data as it doens't need to be reactive
  },
  computed: { 
    customMetUploadSelected(){
      return this.customMetUploadRadioValue == 2 ? true : false;
    },
    lngIsValid(){
      return this.isLngStatusValid;
    },
    latIsValid(){
      return this.isLatStatusValid;
    },
    project() {
      return {
        name: this.projectName,
        address_1: this.address1,
        address_2: this.address2,
        latitude: this.latitude,
        longitude: this.longitude,
        fetch_met: (this.fetchMet || this.fetchMetDefault),
        generateSurrounds: this.generateOSMSurronds,
        client_id: this.clientId,
        image: null
      };
    },
    metData() {
      let metaData = {
        station: this.customMetUploadRadioValue==0 ? this.selectedStationData : {}, 
        futureMet: this.customMetUploadRadioValue==3 ? { timeframe: this.futureMetTimeFrame, climateScenario: this.futureMetClimateScenario } : {},
        custom_met_data: this.customWeatherDataFile?.files[0].path,
        custom_met_data_file_format: this.metFileFormatRadioValue,
        custom_met_data_height: this.customWeatherDataHeight,
      };
      if(this.customWeatherDataLabel?.length > 0 ){
        metaData['custom_met_data_label'] = this.customWeatherDataLabel;
      }
      return metaData;
    },
    canFetchMetManually() {
      return this.allowedPermissions[FETCH_MET_MANUALLY];      
    },
    fetchMetDefault() {
      return !this.canFetchMetManually;
    },
    stepText() {
      if (this.fetchMet) {
        if (this.tabNum == 0) {
          return 'Step 1 of 4: Project Details';
        } else if (this.tabNum == 1) {
          return 'Step 2 of 4: Locations and Surrounds';
        }else if (this.tabNum == 2) {
          return 'Step 3 of 4: Weather';
        }else if (this.tabNum == 3) {
          return 'Step 4 of 4: Review';
        }
      } else {
        if (this.tabNum == 0) {
          return 'Step 1 of 3: Project Details';
        } else if (this.tabNum == 1) {
          return 'Step 2 of 3: Review';
        }else if (this.tabNum == 2) {
          return 'Step 3 of 3: Review';
        }
      }
      return '';
    },   
    canSubmit() {
      if (this.customMetUploadRadioValue==2) { //custom upload
        return this.customMetUploadComplete;
      } else {   
        return true;
      }
    },
    ...mapGetters(['allowedPermissions'])
  },
  methods: {
    setMetFileFormatRadioValue(value) {
      this.metFileFormatRadioValue = value;
    },
    setCustomWeatherDataHeight(height) {
      this.customWeatherDataHeight = height;
    },
    setCustomWeatherDataLabel(label) {
      this.customWeatherDataLabel = label;
    },
    setCustomMetUploadComplete(value) {
      this.customMetUploadComplete = value;
    },
    setCustomWeatherDataFile(file) {
      this.customWeatherDataFile = file;
    },
    setCustomMetUploadRadioValue(value) {
      this.customMetUploadRadioValue = value;
    },
    setSelectedStationData(data) {
      this.selectedStationData = data;
    },
    setTimeframe(data) {
      this.futureMetTimeFrame = data;
    },
    setClimateScenario(data) {
      this.futureMetClimateScenario = data;
    },
    setWeatherDataFormDisabled(disabled) {
      this.weatherDataFormDisabled = disabled;
    },
    setLngStatus(lng){
      this.isLngStatusValid = lng.status;
      if (!this.isLngStatusValid) {
        //entered longitude no longer valid.  set this.longitude to null to disable the "next" button 
        this.longitude = null;
      }
    },
    setLatStatus(lat){  
      this.isLatStatusValid = lat.status;
      if (!this.isLatStatusValid) {
        //entered latitude no longer valid.  set this.latitude to null to disable the "next" button 
        this.latitude = null;
      }
    },
    setCoordinates(coordinates){
      //this method is called by emitted events from the SurroundsGeolocator and will be called if coordinates are set from address search, mouse click, or marker dropped
      
      
      if(coordinates.address){
        this.address1 = coordinates.address;
      }
      if(coordinates.clickedCoordinates){
        //map was clicked.  Update the typedPosition Lat/Lng, which are bound to the displayed lat/lng fields.  These are passed as props
        //back to the SurroundsGeolocator where they are watched.  If both lat and long are valid, a marker is autodropped

        this.typedPositionLat = parseFloat(coordinates?.clickedCoordinates?.lat.toFixed(6));
        this.typedPositionLng = parseFloat(coordinates?.clickedCoordinates?.lng.toFixed(6));
      }

      if (coordinates.marker) {
        //a marker was dropped, either because the user clicked on the map or because they've entered valid lat/lng in the typedPositionLat/Lng fields.
        //set this.latitude and this.longitude, which are the values actually bound to the project and passed forward to the weather section
        //this.latitude and this.longitude need to be set to a valid value in order to enable the "next" button

        this.latitude = parseFloat(coordinates?.marker?.lat.toFixed(6));
        this.longitude = parseFloat(coordinates?.marker?.lng.toFixed(6));
      }
      
      this.loading = false;
    },
    tabIconClass(tabIndex) {
      if (this.tabNum == tabIndex) {
        return 'rounded-circle  bg-primary p-2';
      } else {
        return 'rounded-circle  bg-secondary p-2';
      }
      
    },
    tabTextClass(tabIndex) {
      if (this.tabNum == tabIndex) {
        return 'text-primary';
      } else {
        return 'text-secondary';
      }
    },   
    async createProject() {
      this.createProjectIsLoading = true;
      const projectId = await this.$store.dispatch('project/createProject', { 
        project: this.project,
        metData: this.metData
      });
      this.resetAll();
      this.$router.push({ 
        name: 'ViewerContainerNoScenarios',
        params: {
          id: Number(projectId),
          tabName: 'properties'
        },
        query: {
          tab: 'scenarios'
        }
      });
    },
    onNext() {   
      if(this.tabNum == 0){ 
        this.tabNum = 1;
      }else if (this.tabNum == 2 && this.fetchMet) {
        this.tabNum = 3;
      }else if (this.tabNum == 1 && !this.fetchMet) {
        this.tabNum = 3;
      }else if ((this.tabNum == 1) || (this.tabNum == 0 && !this.fetchMet)) {              
        this.displayStationsFlag = !this.displayStationsFlag;
        window.dispatchEvent(new Event('resize'));  
        this.tabNum = 2;
      }    
    },
    onPrevious() {      
      if ((this.tabNum == 1) || (this.tabNum == 2 && !this.fetchMet)) {    
        this.tabNum = 0;
      } else if (this.tabNum == 2 && this.fetchMet) {
        this.tabNum = 1;    
      }else if (this.tabNum == 3 && this.fetchMet) {
        this.tabNum = 2;    
      }else if (this.tabNum == 3 && !this.fetchMet) {
        this.tabNum = 1;    
      }
    },
    getWeatherSourceType() {
      if (this.fetchMet) {
        if (this.customMetUploadRadioValue==1) {
          return 'Modelled data at the project site';
        } else if (this.customMetUploadRadioValue==2) {
          return 'Custom upload';
        } else {
          return 'Measured data from a nearby weather station';
        }
      } else {  
        return 'none';
      }
    },
    getWeatherSelection() {
      if (this.fetchMet) {
        if (this.customMetUploadRadioValue==1){
          return `Project Location ${this.latitude}, ${this.longitude}`;
        } else if (this.customMetUploadRadioValue==2) {
          return this.customWeatherDataFile?.files?.at(0)?.name;
        } else {
          if (this.selectedStationData) {
            return this.selectedStationData['name'];
          } else {
            return '';
          }
    
        }
      } else {
        return '';
      }
    },
    updateGenerateOSMSurroundsBox(newValue) {
      this.generateOSMSurronds = newValue;
    },
    updateFetchMetBox(newValue) {
      this.fetchMet = newValue;
      if(this.fetchMet)
        this.displayStationsFlag = !this.displayStationsFlag;
    },
    resetAll() {
      this.createProjectIsLoading = false;
      this.$emit('close-modal');
      this.projectName = null;
      this.address1 = null;
      this.address2 = null,
      this.latitude = null;
      this.longitude = null;
      this.clientId = null;
      // this.fetchMet = null;
      this.stationData = null;
      this.selectedStationData = {};   
      this.tabNum = 0;
      this.stations = null;
      this.selectedStation = null;      
      this.fetchMetStationData = false;
      this.customMetUploadRadioValue = 0;
      this.markeritems = [];
      this.customWeatherDataFile = null;
      this.customWeatherDataHeight = 600;
    },
    nextButtonDisabled() { 
      if(this.tabNum > 0){
        return this.createProjectIsLoading
        || !this.projectName
        || !this.address1
        || !this.latitude
        || !this.longitude
        || this.latitude <= -90 || this.latitude >= 90
        || this.longitude <= -180 || this.longitude >= 180
        || (this.weatherDataFormDisabled && this.tabNum == 2)
        || (this.weatherDataFormDisabled && this.tabNum == 3);
      }
      return this.createProjectIsLoading
        || !this.projectName
        || !this.address1;
    }, 
    ...mapActions({
      getProjectAssetUploadUrl: 'project/getProjectAssetUploadUrl'
    }) 
  },
  watch: {
    showModal(newValue){
      this.createProjectModalIsVisible = newValue;
    }
  },
};
</script>

<style scoped>

.select-weather-label {
  font-size: 0.9em;
  margin-bottom: 0.1em;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;  
}

#updatePosition:hover{
  background-color: #007bff;
}

.map-legend-control {
  padding: 0.313rem;
  border-radius: 0.188rem;
  border: 0.063rem solid #aaa;
  background: rgba(0, 0, 0, 0.5);
}

.legend-marker{
  height: 1.438rem;
}

.button-container > * {
  margin-top: 0; 
  position: relative;   
}

.loading-spinner {
  height: 1.25em;
  width: 1.25em;
  padding: 0 1.5rem;
}

.leaflet-layer {
    position: absolute;
    left: 0;
    top: 0;
}

</style>
<style>
#legend-outer{
  margin-top: 0.75rem;
}

.position-values{
   border: 0.125rem solid #007bff;
   border-radius: 0.188rem;
   padding: 0 0.125rem;
}

label[for="project-met"],label[for="generate-osm-surrounds"]{
  margin-bottom: 0;
}

.vue2leaflet-map.leaflet-container{
  cursor: default;
}

#surrounds-map{
  margin-top: 0.625rem;
}

.coords-contianer{
  gap: 11%;
}

.coords-contianer .formulate-input{
  margin: 0;

}

.station-list .create-project-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 7.5rem;
  float: left;
  text-align: right;
  padding-right: 1.875rem;
}

.fixed-size-window {
  height: 47rem;
  max-width: 45rem !important;
}

.fixed-height-body {
  height: 37.8rem;
}

#generate-osm-surrounds-span{
  margin-left: 0.5em;
  font-size: 0.9em;
}


#longitude_label + div,
#latitude_label + div{
 margin-top: 0;
}

/* Tooltip styling : https://www.npmjs.com/package/v-tooltip#style-examples */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 1rem;
  padding: 0.313rem 0.625rem 0.25rem;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 0.313rem;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 0.313rem;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 0.313rem 0.313rem 0 0.313rem;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -0.313rem;
  left: calc(50% - 0.313rem);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 0.313rem;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 0.313rem 0.313rem 0.313rem;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -0.313rem;
  left: calc(50% - 0.313rem);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 0.313rem;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 0.313rem 0.313rem 0.313rem 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -0.313rem;
  top: calc(50% - 0.313rem);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 0.313rem;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 0.313rem 0 0.313rem 0.313rem;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -0.313rem;
  top: calc(50% - 0.313rem);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 1.5rem;
  border-radius: 0.313rem;
  box-shadow: 0 0.313rem 1.875rem rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.drop-down-weather-menu {
  width: 93%;
  margin-left: 0rem;
}
</style>